import React, {useEffect, useState} from 'react';
import ErrorBoundary from "../shared/ui/ErrorBoundary/ErrorBoundary";
import {Loader} from "../shared/ui/Loader/Loader";
import {Portal} from "../shared/ui/Portal/Portal";
import {ResponseInfo} from 'src/services/getInfoFromBot'
import {useTranslation} from "react-i18next";
import qr from 'src/shared/assets/images/qr.png';

const tg = (window as any)?.Telegram?.WebApp;

function App() {
    const { t, i18n } = useTranslation();
    const [isLoading ] = useState(false);
    const [block, setBlock] = useState(false);
    const [info] = useState<ResponseInfo | null>(null);
    //const navigate = useNavigate();
    //const timer = useRef<ReturnType<typeof setTimeout>>();
    //const interval = useRef<ReturnType<typeof setInterval>>();

    // const getInfo = async (userInfo: string) => {
    //     // setTimeout(() => {
    //     //     //navigate('/friends');
    //     //     setInfo({
    //     //         "ok": true,
    //     //         "err": null,
    //     //         "is_subscribed": false,
    //     //         "user": {
    //     //             "id": 18,
    //     //             "name": "Oleg / Futurecat",
    //     //             "username": "StarFen1X",
    //     //             "telegram_id": 673044775,
    //     //             "parent_id": 1927614,
    //     //             "referrals_count": 1,
    //     //             "educated": 2,
    //     //             "level": 0,
    //     //             "referrals_to_next_level": 3,
    //     //             "direct_referrals_count": 1,
    //     //             "created_at": '2024-07-19T23:40:00Z'
    //     //         }
    //     //     })
    //     //     // 5527038462
    //     //    // setIsOg(true);
    //     //     seIsLoading(false);
    //     // }, 0);
    //
    //
    //     try {
    //         const response = await getInfoFromBot(userInfo);
    //
    //         // if (response.user.educated === 1 || response.user.educated === 2) {
    //         //     navigate('/friends');
    //         // }
    //
    //         if (response.err) {
    //
    //             (window as any).gtag('event', 'error', {
    //                 error_code: response.err || '',
    //                 error_text: response.err || '',
    //                 user_id: tg?.initDataUnsafe?.user?.id || '',
    //             });
    //             // (window as any).dataLayer.push({
    //             //     event: 'error',
    //             //     error_code: response.err || '',
    //             //     error_text: response.err || '',
    //             //     user_id: tg?.initDataUnsafe?.user?.id || '',
    //
    //             // });
    //         } else {
    //             (window as any).gtag('event', 'login', {
    //                 user_id: tg?.initDataUnsafe?.user?.id || '',
    //                 session_id: tg?.initDataUnsafe.hash || '',
    //             });
    //
    //             // (window as any).dataLayer.push({
    //             //     event: 'login',
    //             //     user_id: tg?.initDataUnsafe?.user?.id || '',
    //             //     session_id: tg?.initDataUnsafe.hash || '',
    //
    //             // });
    //         }
    //
    //         timer.current = setTimeout(() => {
    //             setInfo(response);
    //             clearTimeout(timer.current);
    //             seIsLoading(false);
    //         }, 1200)
    //
    //     } catch (error: any) {
    //         console.warn('error', error.response);
    //
    //         (window as any).gtag('event', 'error', {
    //             error_code: error?.code || '',
    //             error_text: error?.message || '',
    //             user_id: tg?.initDataUnsafe?.user?.id || ''
    //         });
    //         // (window as any).dataLayer.push({
    //         //     event: 'error',
    //         //     error_code: error?.code || '',
    //         //     error_text: error?.message || '',
    //         //     user_id: tg?.initDataUnsafe?.user?.id || ''
    //
    //         // });
    //     }
    // }

    // const checkSubscribeAsync = async (userInfo: string) => {
    //     try {
    //         const response = await checkSubscribe(userInfo);
    //         if (response.err) {
    //             (window as any).gtag('event', 'error', {
    //                 error_code: response.err || '',
    //                 error_text: response.err || '',
    //                 user_id: tg?.initDataUnsafe?.user?.id || '',
    //             });
    //
    //             // (window as any).dataLayer.push({
    //             //     event: 'error',
    //             //     error_code: response.err || '',
    //             //     error_text: response.err || '',
    //             //     user_id: tg?.initDataUnsafe?.user?.id || '',
    //
    //             // });
    //         }
    //         setInfo(response);
    //     } catch (error: any) {
    //         console.warn('error', error);
    //         (window as any).gtag('event', 'error', {
    //             error_code: error?.code || '',
    //             error_text: error?.message || '',
    //             user_id: tg?.initDataUnsafe?.user?.id || ''
    //         });
    //
    //         // (window as any).dataLayer.push({
    //         //     event: 'error',
    //         //     error_code: error?.code || '',
    //         //     error_text: error?.message || '',
    //         //     user_id: tg?.initDataUnsafe?.user?.id || ''
    //
    //         // });
    //     }
    // }

    // const checkSubscribeHandler = () => {
    //     void checkSubscribeAsync(tg.initData)
    // }

    useEffect(() => {
        tg.ready();
        tg.setHeaderColor('#000');
        tg.setBackgroundColor('#000')
        tg.expand();
        if (tg.platform === 'tdesktop' || tg.platform === 'macos' || tg.platform === 'weba' || tg.platform === 'web') {
            const desktop = document.querySelector('.desktop');
            desktop?.classList.add('desktopImageActive');
            setBlock(true);
        }
        //const background = document.querySelector('.imageBackground');

        const lang = tg.initDataUnsafe?.user?.language_code
        if (lang && lang === 'ru') {
            void i18n.changeLanguage('ru');
        } else {
            void i18n.changeLanguage('en');
        }

        //if (!info) {
            //background?.classList.add('activeImgBackground');
            //void getInfo(tg.initData);
        //}

        //eslint-disable-next-line
    }, [info]);


    // useEffect(() => {
    //     if (info) {
    //         interval.current = setInterval(() => {
    //             getInfo(tg.initData);
    //         }, 15000)
    //     }
    //
    //     return () => {
    //         clearInterval(interval.current);
    //     }
    //     //eslint-disable-next-line
    // }, [info]);


    return (
        <>
            <div className={'desktop'}>
                <p className={'textHeaderDesktop'}>{t('mobilePlay')}</p>
                <div className={'imageWrapper'}>
                    <img className={'desktopImage'} src={qr} alt={'qr'} />
                    <p className={'textBot'}>{'@cityholder'}</p>
                </div>
            </div>
            <Loader isLoaded={isLoading || !info} />
            <div className="app">
                <Portal>
                    {/*<img className={'imageBackground'} src={background} alt={'background'} />*/}
                    {/*<div className={'background'}>*/}
                    {/*    <div className={'background-image'}/>*/}
                    {/*</div>*/}
                </Portal>
                {!block && <div className="app__container">
                    <main className='main'>
                        <ErrorBoundary>
                            {/*<Routes>*/}
                            {/*    <Route path={''}*/}
                            {/*        element={!info ? <></> :*/}
                            {/*            <MainPage info={info} onCheckSubscribe={checkSubscribeHandler} />} />*/}
                            {/*    <Route path={'/friends'}*/}
                            {/*        element={<FriendsHouse info={info} isLoading={isLoading || !info}*/}
                            {/*            onCHeckSubscribe={checkSubscribeHandler} />} />*/}
                            {/*</Routes>*/}

                        </ErrorBoundary>
                    </main>
                </div>}
            </div>
        </>
    );
}

export default App;
