import cl from "classnames";
import cls from './Loader.module.scss'
import './Loader.css'
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

interface LoaderProps {
    isLoaded?: boolean;
}

const tg = (window as any)?.Telegram?.WebApp;

export const Loader = ({ isLoaded }: LoaderProps) => {
const { t } = useTranslation();

    useEffect(() => {
        (window as any).gtag('event', 'screen_view', {
            'screen_name': 'start_screen_before_auth',
        });
    }, []);

    const openLinkHandler = () => {
        if ((window as any).Telegram) {
            tg.openTelegramLink('https://t.me/cityholder/game?startapp');
        }
    }

    return (
        <div className={cl(cls.loaderComponent)} onClick={openLinkHandler}>
            <div className={cls.wrapper}>
            <h3 className={cls.title}>{t('loader.title')}</h3>
            <p className={cls.text}>{t('loader.textOne')}</p>
            <p className={cls.text}>{t('loader.textTwo')}</p>
            <button className={cls.link}>{'t.me/cityholder'}</button>
            </div>
        </div>
    )
};
