import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import '../src/app/styles/index.scss';
import {BrowserRouter} from "react-router-dom";
import Backend from 'i18next-http-backend'
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector'

import {initReactI18next} from 'react-i18next';
import {Helmet} from "react-helmet";


i18n
    .use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({
        lng: 'en',
        fallbackLng: 'en',
        debug: false,
        react: {
            useSuspense: false,
        },
        interpolation: {
            escapeValue: false,
            skipOnVariables: false,
        },
        backend: {
            loadPath: './locales/{{lng}}.json',
        },
    });

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


root.render(
    <>
        <Helmet>
            <meta property="og:title" content="City Holder"/>
            <meta property="og:site_name" content="City Holder"/>
            <meta property="og:description" content="somethin delisiocs"/>
            <meta property="og:image" content="https://www.bdcnetwork.com/sites/default/files/AdobeStock_624499925.jpg"/>
        </Helmet>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </>
)
;
