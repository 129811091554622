import cls from './ErrorHint.module.scss'
import React from "react";

export const ErrorHint = () => {
    const reloadPageHandler = () => {
        window.location.reload();
    }
    return (

        <div className={cls.errorHint}>
            <p className={cls.text}>{'Упс...Что-то пошло не так'}</p>
            <p className={cls.text}>{'Попробуйте перезагрузить страницу'}</p>
            <button className={cls.button} onClick={reloadPageHandler}>{'Перезагрузить'}</button>
        </div>

    )
};