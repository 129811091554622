import React from 'react';
import {ErrorHint} from "../../ErrorHint/ui/ErrorHint";


interface IErrorBoundaryProps {
    children?: React.ReactNode | Element;
}

interface IErrorBoundaryState {
    error: any;
    errorInfo: any;
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
    constructor(props: IErrorBoundaryProps) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null,
        };
    }

    componentDidCatch(error: any, errorInfo: any) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
    }

    render() {
        const {error, errorInfo} = this.state;
        if (error || errorInfo) {
            return <ErrorHint/>;
        }
        return <>{this.props.children}</>;
    }
}

export default ErrorBoundary;
